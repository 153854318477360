document.querySelectorAll("button[name=do][value=submit]").forEach(submitButton => {
	submitButton.hidden = true;
});

autoForm();
slider();
iframe();

function autoForm() {
	const parser = new DOMParser();
	let form = document.querySelector("form.auto");
	if(!form) {
		return;
	}

	form.addEventListener("change", e => {
		let context = e.target.closest("label");
		if(context.classList.contains("slider")) {
			return;
		}

		while(context.nextElementSibling) {
			context = context.nextElementSibling;
			if(context instanceof HTMLLabelElement) {
				if(context.classList.contains("slider")) {
					continue;
				}

				context.querySelector("select").value = "";
			}
		}

		form.querySelector('[name=do][value=submit]').click();
	});

	form.addEventListener("submit", e => {
		if(e.submitter.value === "email") {
			return;
		}

		e.preventDefault();
		document.body.classList.add("loading");

		let submitterValue = e.submitter.value;

		const formData = new FormData(form);
		formData.append(e.submitter.name, submitterValue);

		if(submitterValue === "reset") {
			console.log("reset");
			window.scrollTo({top: 0, behavior: "smooth"});
			if("parentIFrame" in window) {
				parentIFrame.scrollTo(0, 0);
			}
		}

		fetch(location.href, {
			method: "post",
			body: formData,
		}).then(response => {
			if(response.ok) {
				return response.text();
			}
			else {
				console.log(response);
			}
		}).then(/** @param {string} html */(html) => {
			const newDocument = parser.parseFromString(html, "text/html");
			form.innerHTML = newDocument.forms[0].innerHTML;
			slider();
			document.body.classList.remove("loading");
		});
	});
}

function slider() {
	let range = document.querySelector("input[type='range']");
	if(!range) {
		return;
	}
	let output = range.closest("label").querySelector("output");
	let finalSelect = document.querySelector("select[name='layer7']");
	if(!finalSelect || !finalSelect.value) {
		range.closest("label").classList.add("hidden");
		return;
	}

	let insulationSelect = document.querySelector("select[name='layer5']");

	let regex = /(.+) \(?(\d+)(mm)?/g;
	let matches = [...insulationSelect.value.matchAll(regex)];
	let prefix = matches[0][1];
	let thickness = matches[0][2];

	let availableThicknessList = [];
	insulationSelect.querySelectorAll("option").forEach(option => {
		if(!option.value.startsWith(prefix)) {
			return;
		}

		let innerMatches = [...option.value.matchAll(regex)];
		availableThicknessList.push(parseInt(innerMatches[0][2]));

	});
	availableThicknessList.sort((a,b) => a - b);

	range.min = Math.min(...availableThicknessList);
	range.max = Math.max(...availableThicknessList);
	range.step = 5;
	range.closest("label").querySelector(".range-labels .min").dataset["label"] = range.min;
	range.closest("label").querySelector(".range-labels .max").dataset["label"] = range.max;

	range.addEventListener("input", e => {
		output.value = range.value;
	});
	range.addEventListener("change", e => {
		range.value = availableThicknessList.reduce(function(prev, curr) {
			return (Math.abs(curr - range.value) < Math.abs(prev - range.value) ? curr : prev);
		});

		output.value = range.value;

		insulationSelect.querySelectorAll("option").forEach(option => {
			if(!option.value.startsWith(prefix)) {
				return;
			}

			let innerMatches = [...option.value.matchAll(regex)];
			if(parseInt(innerMatches[0][2]) === parseInt(range.value)) {
				insulationSelect.value = option.value;
			}
		});
		insulationSelect.form.querySelector("button[name='do'][value='submit']").click();
	});

	range.value = thickness;
	output.value = range.value;
}

function iframe() {
	if(window.top !== window) {
		document.documentElement.classList.add("embedded");
	}
}
